import React, { useEffect } from "react"
import config from "../../config/config"
import { getUser, isLoggedIn } from "../../services/auth"
import axios from "axios";

const ElementUserForm = ({data}) => {
    const element = JSON.parse(data.Data);
    var inlineStyle = {};
    if(element.PaddingTop != 0){
        inlineStyle.paddingTop = element.PaddingTop + 'rem';
    }
    if(element.PaddingBottom != 0){
        inlineStyle.paddingBottom = element.PaddingBottom + 'rem';
    } 
    if(element.BGColor != null){
        inlineStyle.backgroundColor = element.BGColor;
    }
    const host = config.host
    const pageID = element.ID
    const formType = 'ElementForm'
    
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const handleSubmit = e => {
                e.preventDefault();

                const formData = new FormData(e.target);
                formData.append('PageID', element.ID);
                formData.append('FormType', formType);
                axios.post(config.host + "/tribecaapi/userform/", formData).then(response => {
                    let _response_data = response.data;
                    if(_response_data.Success == 1){
                        if(_response_data.RedirectLink){
                            if (typeof window !== 'undefined') {
                                window.location.href = _response_data.RedirectLink;
                            }
                        }
                    }
                }).catch(function (error) {
                });
            }

            const form = window.document.getElementsByTagName('form')[0];
            form.addEventListener('submit', e => handleSubmit(e), false);
            
            const search = typeof window !== "undefined" ? window.location.search : "/"
            const params = new URLSearchParams(search)
            var utm_fields = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term']
            utm_fields.forEach(function(key, value) {
                let hidden_field = document.getElementsByName(key)
                if(hidden_field.length > 0){
                    if(typeof window !== "undefined" && window.localStorage) {
                        hidden_field[0].value = window.localStorage.getItem(key)
                    }
                }
            })
            // clean up event
            return form.removeEventListener('submit', e => handleSubmit(e));
        }
        return () => {}
    }, [])
  return (
    <div id={`content-${element.ID}`} className="element elementuserform">
      <section className={`section`} style={inlineStyle}>
        <div className="container">
            {element.ShowTitle == 1 && ( 
                <h2 className="title title-long title-long-white text-center">{element.Title}</h2> 
            )}
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-10">
              <div dangerouslySetInnerHTML={{__html: element.HTMLForm}} />
            </div>
          </div>
        </div>
      </section>
      {element.LineDivider == 1 ? (
        <div className="container">
          <hr className="hr-black m-0" />
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default ElementUserForm
